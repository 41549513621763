.errorMessage {
  background-color: #da4c4c;
  text-align: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  color: #fff;
  padding: 8px 0;
  &__triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #da4c4c;
    position: absolute;
    top: -3px;
    left: 50%;
  }
}
