.appmodal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1040;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
  }

  &--show {
    display: block;
  }
  &--hide {
    display: none;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1050;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    animation: animatetop 1s ease;
    width: fit-content;
    margin: 5.75rem auto;
    border-radius: 8px;
    box-shadow: 0px 6px 40px rgba(82, 81, 81, 0.22);
    border-radius: 14px;
    $breakpoint-mobile: 540px;
    @media (max-width: $breakpoint-mobile) {
      width: 90%;
      margin: 1.75rem auto;
    }
  }
  &__close {
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
  }
}

@keyframes animatetop {
  from {
    top: -200px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
