@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirLTStd-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirLTStd-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirLTStd-Black.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
}

* {
  font-family: "Avenir";
}
body {
  // font-size: 14px;
  font-size: 16px;
  font-family: "Avenir";
}

.wider_container {
  max-width: 1360px;
  // padding: 0;
}
.main-page-content {
  min-height: calc(100vh - 135px);
}

a {
  color: #828282;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    // color: ;
  }
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9e9e9;
  z-index: 2;
}

#overlay .Toastify__toast-container--top-center {
  top: 15em;
  left: 50%;
  margin-left: -160px;
}
.success .css-1vibr2z,
[data-css-1vibr2z] {
  margin: 0 !important;
  background: transparent !important ;
  color: #07bc0c !important;
  opacity: 1 !important;
  font-size: 22px !important;
}

.Toastify__toast-container {
  width: auto;
  min-width: 300px;
}
.Toastify__toast--success {
  background: #161531;
}

.Toastify__toast--error::before {
  content: url(assets/images/svg//check-error.svg);
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.Toastify__toast--success::before {
  content: url(assets/images/svg/check-toast.svg);
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.Toastify__toast {
  border-radius: 8px;
  font-weight: 500;
  min-height: 50px;
}
.Toastify__toast-body {
  font-size: 14px;
  text-align: center;
}

.Toastify__close-button {
  display: none;
}

.scrollable--course-card a:hover,
.scrollable--course-card a {
  color: #161531;
}
