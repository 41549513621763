.cart101_wrapper {
  background: #fbfbfd;
  border-radius: 0px;
  width: 508px;
  position: absolute;
  right: 60px;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgba(82, 81, 81, 0.22);
  border-radius: 0px 0px 0px 14px;
}

.cart101_wrapper .overflow_wrapper {
  padding: 20px 20px;
  height: 300px;
}

.cart101_content {
  display: flex;
  margin: 5px;
}

.cart101_content img {
  height: 70px;
  width: 138px;
  object-fit: cover;
  border-radius: 10px;
}

.cart101_text .span_text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #737272;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 14px;
  display: inline-block;
}

.cart101_text .span_name {
  left: 0%;
  right: 61.72%;
  top: 49.47%;
  bottom: 27.37%;
  background: #f3f3f4;
  border-radius: 4px;
  padding: 3px 10px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: flex-start;
  margin: 0px 7px;
  display: inline-block;
  margin: 8px 14px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  /* dark purple */
  color: #161531;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: center;
}

.cart101_text .span_amount {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  /* really dark purple */
  color: #161531;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: center;
  margin: 6px 14px;
}

.cart101_content .amount_and_icon {
  display: flex;
  justify-content: space-between;
}

.cart101_content .amount_and_icon img {
  height: 15px;
  width: 15px;
  margin: 0 2px;
}

.cart101_footer {
  background: linear-gradient(
      177.35deg,
      rgba(74, 76, 89, 0.03) 0.95%,
      rgba(93, 92, 122, 0.15) 99%
    ),
    #f3f4f4;
  border-radius: 0px 0px 14px 14px;
  padding: 18px 38px 18px;
}

.cart101_footer .note_items {
  display: flex;
  justify-content: space-between;
}

.cart101_footer .note_items span {
  display: inline-block;
  padding-bottom: 10px;
}

.cart101_footer .footer_total {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */
  /* header */
  color: #161531;
}

.cart101_footer .footer_amount {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  /* really dark purple */
  color: #161531;
}

.cart101_footer button {
  background: #161531;
  border-radius: 8px;
  width: 439px;
  height: 53px;
}

.cart101_wrapper .cart101 {
  padding: 50px 0px;
}

.cart101 .align-items-center span {
  margin-top: -20px;
  text-align: center;
}

@media screen and (max-width: 997px) {
  .cart101_wrapper {
    right: 0px;
    z-index: 4;
    top: 60px !important;
  }
}
