.inputradio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  font-size: 14px;

  &__input {
    display: none;
  }
  &__radio {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #d8e4e2;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 2px;
    position: relative;
    bottom: 3px;
  }

  &__radio::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #fff;
    border: 3px solid #17469a;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
  }

  &--background {
    padding: 15px 20px;
    border: 1px solid rgba(148, 147, 168, 0.21);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
    transition: 0.2s ease;
    width: 100%;
  }
  &--background:hover {
    border-radius: 8px;
    background: #f3f4f4;
  }
}

.inputradio__input:checked + .inputradio__radio::after {
  transform: scale(1.5);
}

.inputradio__input:disabled ~ span, .inputradio__input:disabled + .inputradio__radio{
  opacity: 0.7;
  cursor: not-allowed!important;
}
