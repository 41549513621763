.notification_wrapper {
  background: #fbfbfd;
  border-radius: 0px;
  width: 508px;
  position: absolute;
  right: 60px;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgba(82, 81, 81, 0.22);
  border-radius: 0px 0px 0px 14px;
}

.notify_header {
  padding-bottom: 20px;
  background: #fbfbfd;
  border-radius: 0px;
  padding: 18px 38px;
}

.notify_header h1 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #161531;
}

.notify_content {
  padding: 18px 38px;
}

.notify_content .span_text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #737272;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 14px;
  display: inline-block;
  max-width: 339px;
}

.notify_content {
  display: flex;
}

.notify_content .avatar {
  /*   width:45px;*/
  text-align: center;
  padding-top: 3px;
}

.notify_text .span_time {
  display: block;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #737272;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 14px;
}

.notify_footer {
  border: 1px solid #f2f2f5;
  text-align: center;
  padding: 18px 38px;
}

.notify_footer h1 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  /* dark purple */
  color: #161531;
}

.overflow_wrapper {
  height: 200px;
  overflow-y: auto;
}

.notification_wrapper .announcement {
  padding-bottom: 100px;
}



@media screen and (max-width: 700px) {
 .notification_wrapper {
    right: 0px;
    z-index: 4;
    top: 60px !important;
      width: 80%;
  }

  .notify_content .span_text {
  max-width: 339px; }
}

@media screen and (max-width: 480px) {
 .notification_wrapper {
    right: 0px;
    z-index: 4;
    top: 60px !important;
      width: 100%;
  }

  .notify_content .span_text {
  max-width: 239px; }
}


