input {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  border: none;
  &[type='search']{
    text-indent: 19px;
    appearance: none;
    padding: 11px 14px;
    &::focus{
      outline: none;
    }
  }
  &[type='search']::placeholder{
    text-indent: 19px;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    
    color: #a3a3a9;
    opacity: 1; /* Firefox */
  }
  &:focus {
    border: 0.5px solid #161531;
    box-sizing: border-box;
  }
  &[type='radio'] {
    width: 20px;
  }
  &:checked {
    background-color: green;
  }
}
.counter {
  background: #f2f2f2;
  border-radius: 6px;
  padding: 2px 8px;
  margin-bottom: 2px;

  &--negative {
    color: #fff;
    background-color: #da4c4c;
  }
}

.input-wrap {
  width: 100%;
  position: relative;
  img{
    position: absolute;
    left: 0px;
    top: 33%;
    left: 12px;
  }
}

input.hide-clear[type="search"]::-webkit-search-decoration,
input.hide-clear[type="search"]::-webkit-search-cancel-button,
input.hide-clear[type="search"]::-webkit-search-results-button,
input.hide-clear[type="search"]::-webkit-search-results-decoration {
  display: none; 
}