.dropdown-content.navmenu {
  position: absolute;
  background: #161531;
  width: 100%;
  left: 0;
  top: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-position: right top;
  background-attachment: fixed;
  height: 300px;
  overflow-y: scroll;
  padding-bottom: 60px;
  transition-property: height;
  transition-duration: 1s;
  transition-timing-function: ease-in;
}

.dropdown-content.navmenu table td {
  padding-left: 10px !important;
}

.dropdown-content table {
  margin: 20px 0px;
}

/* Create three equal columns that floats next to each other */
.navmenu td {
  float: left;
  width: 30%;
}

.navmenu td a {
  float: none;
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  /* white */
  color: #ffffff;
}

@media screen and (max-width: 997px) {
  .navmenu td {
    float: left;
    width: 100%;
  }

  .dropdown-content.navmenu {
    position: relative;
    background: #161531;
    width: 100%;
    left: 0;
    top: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-bottom: 20px;
    height: 300px;
    overflow-y: scroll;
    padding-bottom: 60px;
    transition-property: height;
    transition-duration: 1s;
    transition-timing-function: ease-in;
  }
}

@media screen and (max-width: 997px) {
  .notification_wrapper {
    width: 508px;
    position: absolute;
    right: 0px;
    z-index: 4;
    top: 60px !important;
  }
}
