.login {
  background: #fff;
  padding: 45px 45px 0 45px;
  width: 400px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin: 0;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #737272;
    margin: 0;
  }
  &__social {
    margin-top: 27px;
  }
  &__divider {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
  }
  &__line {
    border: 1px solid #e5e5e5;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  &__forgot {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    max-width: 250px;
    padding-bottom: 56px;
    margin: 0 auto;
  }
  &__footer {
    padding: 20px 0;
    text-align: center;
    background: #f3f4f4;
    border-radius: 0px 0px 14px 14px;
  }
  &__signup-option {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  button {
    font-size: 15px;
    width: 100%;
  }
}

@media screen and (max-width:480px){
  .login {
    width: 100%;
}
}