body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F7F7 !important;
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
 color: #161531;
}

/* rade please help check this and pick the most appropriate one */
#root{
  height: 100%;
  background: #F7F7F7;
}
/* :root{
  height: 100%;
  background: #F7F7F7;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
