.profile-dropdown {
  position: absolute;
  right: -60px;
  top: 58px;
  color: #161531;
  z-index: 100;
  font-size: 14px;
  min-width: 270px;
  &__container {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(82, 81, 81, 0.12);
    border-radius: 0px 0px 0px 14px;
    padding: 25px 0;
  }
  &__item {
    padding: 18px 35px;
    color: #161531;
  }
  &__item--red {
    color: #da4c4c;
  }
  &__item:hover {
    color: #161531;
  }
  &__item--red:hover {
    color: #da4c4c;
  }
  &__item-hoverable:hover {
    color: #161531;
    background: #dcdde2;
  }
  &__item--bold {
    font-weight: 700;
  }
  &__name {
    background: #e9effb;
    padding: 0px 5px;
    /* invisible purple stroke */

    border: 0.5px solid rgba(148, 147, 168, 0.21);
    box-sizing: border-box;
    border-radius: 4px;
  }
}
