.avatar {
  cursor: pointer;
  border-radius: 40%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9effb;
  position: relative;
  border: 1px solid rgba(148, 147, 168, 0.21);
  box-sizing: border-box;
  &__text {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #161531;
  }
  &__trash {
    position: absolute;
    color: #fff;
  }
  &--background {
    position: absolute;
    width: 100%;
    border-radius: 40%;
    height: 100%;
    background: #b3b3b34a;
  }
}
